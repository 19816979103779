// 开发环境域名

const host_development = 'http://shopkxbfnsm4.com'

export default {
  // 版本
  version: '2.1.0',
  baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
  tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
